import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import styles from './style/rattish.module.scss'

import bg1 from '../media/svg/bg1.svg'
import bg2 from '../media/svg/bg2.svg'
import bg1Mobile from '../media/svg/bg1-mobile.svg'
import bg2Mobile from '../media/svg/bg2-mobile.svg'

const BackgroundHand = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles.BackgroundHand}
    width="683"
    height="1016"
    fill="none"
    viewBox="0 0 683 1016">
    <path
      fill="#AF24BA"
      d="M284.793 935.202c5.849-14.901 12.05-30.118 20.95-43.251 15.263-22.532 35.171-42.137 48.75-65.521 19.454-33.51 35.045-69.275 52.144-104.144 7.486-15.259 15.61-30.315 21.676-46.144 7.623-19.917 14.669-40.222 19.789-60.9 12.417-50.127 40.273-91.491 71.49-131.182 16.26-20.665 33.011-40.977 48.477-62.221 15.458-21.224 29.937-43.19 44.161-65.271 6.437-9.997 10.833-21.285 16.845-31.59 11.582-19.86 25.428-38.613 34.999-59.368 8.443-18.309 13.171-38.516 17.931-58.275 2.781-11.537-4.188-21.563-13.72-26.756-10.705-5.834-23.342-10.406-34.599-.122-8.459 7.734-17.317 15.453-23.993 24.64-14.975 20.609-27.004 43.56-43.284 62.993-39.603 47.276-80.566 93.425-121.783 139.307-14.475 16.113-26.551 15.952-49.42 3.798-14.103-7.503-17.34-16.184-13.674-37.086.734-4.21 1.84-8.352 2.686-12.537 4.405-21.787 8.451-43.654 13.251-65.352 8.962-40.568 12.283-81.726 15.503-123.051 3.179-40.773 9.594-81.284 14.19-121.954 2.832-25.039-12.422-45.65-35.903-49.63-20.913-3.54-34.654 7.147-40.232 34.353-5.482 26.742-7.641 54.168-13.428 80.824-8.041 37.054-19.204 73.444-26.901 110.557-4.718 22.746-5.374 46.318-7.994 69.508-2.906 25.78-5.523 51.606-9.35 77.252-.734 4.9-5.722 10.764-10.262 12.827-2.283 1.038-9.132-4.665-11.932-8.699-12.249-17.69-30.506-23.088-49.518-20.334-18.45 2.675-29.702 18.008-36.744 34.462-7.64 17.848-6.797 17.081-26.703 18.023-9.439.451-20.963 3.787-27.532 10.042-29.266 27.85-42.329 65.082-54.78 102.306-9.323 27.86-18.501 55.787-27.191 83.855-5.038 16.26-11.638 32.667-12.828 49.346-2.239 31.292-.74 62.832-1.386 94.259-.732 35.569-4.885 70.593-17.324 104.342-3.264 8.854-23.053 50.565-15.136 86.878C9.935 977.7 223.368 1021.57 240 1015.29c16.633-6.27 33.463-51.246 44.792-80.088z"
      opacity="0.4"></path>
  </svg>
))

const StoryCat1 = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="124"
    height="164"
    fill="none"
    viewBox="0 0 124 164">
    <path
      fill="#EEB641"
      d="M26.758 95.952c3.2 2.5 6.34 5.069 9.6 7.477 13.684 10.1 28.685 17.356 45.471 20.571 4.265.816 8.62 1.165 12.933 1.728 2.211 0 4.422.056 6.63 0 7.426-.2 12.8-5.638 12.7-12.8-.1-7-5.648-12.236-12.961-12.36-2.846-.048-5.688-.324-8.532-.5A64.776 64.776 0 0172.307 95c-16.07-6.6-29.316-17.066-40.594-30.147-.2-.287-.37-.593-.506-.915-.44-.46-.909-.9-1.315-1.384-4.7-5.671-11.949-6.593-17.527-2.218a12.474 12.474 0 00-2.09 17.93c1.838 2.367 3.756 4.69 5.815 6.865 3.481 3.678 7.102 7.221 10.668 10.821z"></path>
    <path
      fill="#2B01FD"
      d="M26.758 95.952c-3.564-3.6-7.187-7.143-10.669-10.819-2.059-2.175-3.977-4.5-5.815-6.865a12.473 12.473 0 012.09-17.93c5.578-4.375 12.83-3.453 17.527 2.218.405.488.875.924 1.315 1.384 1.3-2.882 2.512-5.787 1.942-9.068a12.758 12.758 0 00-11.5-10.442c-6.89-.569-12.3 2.755-14.482 8.928a127.777 127.777 0 00-6.253 27.31 112.805 112.805 0 002.15 40.435 91.332 91.332 0 0015.56 33.611c3.512 4.7 8.437 6.292 14.074 4.978a12.198 12.198 0 009.849-10.121 11.155 11.155 0 00-2.08-8.931c-8.67-12.016-12.859-25.536-13.63-40.217-.077-1.485-.054-2.979-.078-4.471zM92.597 100.07c2.844.172 5.686.449 8.532.5 7.313.123 12.856 5.359 12.961 12.36.107 7.161-5.27 12.6-12.7 12.8-2.209.059-4.42 0-6.63 0a63.684 63.684 0 01-6.469 18.771 12.497 12.497 0 005.07 17 12.177 12.177 0 009.444 1.165 12.188 12.188 0 007.402-5.979c6.537-11.749 10.439-24.289 10.176-37.864a41.235 41.235 0 00-5.3-19.9 11.959 11.959 0 00-12.16-6.4 11.679 11.679 0 00-10.326 7.547z"></path>
    <path
      fill="#4BD12A"
      d="M23.047 18.191a18.053 18.053 0 1036.105-.273 18.053 18.053 0 00-36.105.273z"></path>
    <path
      fill="#020003"
      d="M105.462 83.743a18.091 18.091 0 00.045-36.179 18.091 18.091 0 00-12.83 30.865 18.087 18.087 0 0012.785 5.314z"></path>
  </svg>
))

const StoryCat2 = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="167"
    height="163"
    fill="none"
    viewBox="0 0 167 163">
    <path
      fill="#2A00FE"
      d="M79.475 37.61c-1.555 2.3-3 4.507-4.523 6.667C62.205 62.392 46.23 76.55 25.334 84.62a62.555 62.555 0 01-9.413 2.928 12.06 12.06 0 01-14.18-8.885 12.225 12.225 0 018.162-14.861c19.33-5.523 33.524-17.787 44.88-33.836A110.165 110.165 0 0066.905 7.673 11.7 11.7 0 0178.567.032a29.814 29.814 0 015.025.193c4.442.83 7.322 3.662 8.971 7.75a109.523 109.523 0 0017.129 28.778c10.483 12.74 23.17 22.276 39.233 26.9a12.289 12.289 0 018.7 14.73 12.019 12.019 0 01-9.673 9.266 12.02 12.02 0 01-4.705-.115 83.384 83.384 0 01-25.735-11.1c-14.882-9.3-26.574-21.781-36.01-36.475a29.508 29.508 0 00-1.2-1.725 5.147 5.147 0 00-.827-.624z"></path>
    <path
      fill="#F86EFD"
      d="M146.975 160.317a11.833 11.833 0 01-11.846-8.9c-3.309-13.64-3.759-27.3.829-40.793a40.79 40.79 0 019.329-15.3 12.345 12.345 0 0117.24-.7c4.878 4.268 5.6 12.184 1.176 17.04-4.174 4.581-5.736 10.007-6.164 15.91a62.414 62.414 0 001.431 17.938 12.22 12.22 0 01-2.452 10.274 12.219 12.219 0 01-9.543 4.531zM31.93 129.131a74.993 74.993 0 01-4.009 24.576 11.943 11.943 0 01-12.5 8.292 11.764 11.764 0 01-11.033-9.6 18.009 18.009 0 01.5-7.212c1.74-6.776 2.8-13.591 2.18-20.587a21.98 21.98 0 00-4.385-11.847 12.345 12.345 0 01-.998-13.908 12.339 12.339 0 013.267-3.66c5.562-4.158 13.165-2.946 17.524 2.792 6.604 8.703 9.497 18.229 9.453 31.154z"></path>
    <path
      fill="#EEB641"
      d="M74.431 121.142c13.051-2.97 25.592-.793 37.874 3.817a74.78 74.78 0 016.735 2.928 12.215 12.215 0 016.384 6.905 12.213 12.213 0 01-.408 9.395c-3.06 6.377-10.089 8.647-16.738 5.912a97.547 97.547 0 00-14.932-4.822c-6.472-1.506-12.8-1.119-18.907 2.316-5.706 3.211-12.788.668-16.028-5.156.472-.108.94-.234 1.414-.322a17.508 17.508 0 0013.194-10.148c1.672-3.45 1.752-7.108 1.412-10.825z"></path>
    <path
      fill="#04FCFD"
      d="M74.431 121.144c.341 3.715.261 7.374-1.41 10.829a17.501 17.501 0 01-13.195 10.148c-.475.088-.943.214-1.414.322-4.755.023-9.163-1-12.9-4.163a17.734 17.734 0 01-6.387-14.132 17.726 17.726 0 0134.067-6.35c.462 1.094.828 2.229 1.24 3.346z"></path>
  </svg>
))

const StoryCat3 = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="202"
    height="145"
    fill="none"
    viewBox="0 0 202 145">
    <path
      fill="#2A04FB"
      d="M17.95 36.766c-5.11-4.906-10.7-9.41-15.2-14.824C-2.033 16.18-.282 7.885 5.738 3.02 11.387-1.549 19.03-.87 24.3 4.624c1.542 1.607 3.187 3.115 4.787 4.668.255.402.544.78.865 1.131 13.613 12.6 28.819 22.61 46.873 27.531C82.85 39.597 89.21 40.02 95.415 41c3.5-.007 7.011.188 10.491-.062 7.231-.519 13.448 3.52 15.389 10.264a13.677 13.677 0 01-7.917 16.325 25.453 25.453 0 01-9.106 1.456c-5.078.1-10.165-.284-15.25-.463 1.784-2.9 3.012-5.965 2.718-9.466-.622-7.414-6.082-12.528-14.06-12.994a65.722 65.722 0 01-20.956-4.549c-5.5-2.223-10.177-5.436-13.388-10.7a13.446 13.446 0 00-15.355-5.468c-5.642 1.76-8.77 5.79-10.03 11.423z"></path>
    <path
      fill="#EEB641"
      d="M17.95 36.766c1.26-5.634 4.388-9.664 10.03-11.424a13.445 13.445 0 0115.356 5.468c3.21 5.266 7.884 8.48 13.388 10.7a65.716 65.716 0 0020.957 4.55c7.977.464 13.437 5.578 14.059 12.993.294 3.5-.934 6.562-2.717 9.466-3.6 4.212-8.18 5.735-13.665 5.31-15.291-1.189-29.868-4.664-42.4-13.964-3.916-2.906-7.221-6.71-10.5-10.38a16.932 16.932 0 01-4.508-12.719zM95.417 41.001c-6.206-.98-12.565-1.4-18.591-3.046-18.054-4.923-33.26-14.93-46.873-27.53-.321-.352-.61-.73-.865-1.132 6.433-3.422 14.095-1.782 17.979 4.337 3.453 5.438 8.366 8.69 14.13 10.95a65.357 65.357 0 0020.568 4.305c7.308.386 12.444 4.956 13.652 12.116z"></path>
    <path
      fill="#F86EFD"
      d="M125.915 133.885c-6.351 2.336-12.559 5.255-19.089 6.849-6.95 1.697-13.34-3.274-15-10.481-1.559-6.766 2.222-13.045 9.144-15.27 2.025-.652 4.009-1.434 6.011-2.157a7.864 7.864 0 001.334-.269c16.515-6.415 31.475-15.287 43.254-28.733 3.933-4.487 6.956-9.77 10.394-14.69 1.457-3.007 3.095-5.945 4.335-9.035 2.576-6.429 8.648-10.075 15.249-8.923a13.069 13.069 0 0110.707 13.628 24.31 24.31 0 01-2.561 8.43c-2.034 4.406-4.496 8.612-6.777 12.902-1.749-2.743-3.864-5.082-6.99-6.298-6.627-2.568-13.302-.019-17.04 6.637a62.777 62.777 0 01-12.675 16.094c-4.211 3.796-8.925 6.465-14.791 7.018a12.849 12.849 0 00-11.12 10.898c-.848 5.589 1.305 9.96 5.615 13.4z"></path>
    <path
      fill="#EEB641"
      d="M125.913 133.889c-4.312-3.436-6.463-7.812-5.613-13.394a12.841 12.841 0 0111.12-10.899c5.866-.555 10.58-3.222 14.792-7.019a62.774 62.774 0 0012.674-16.093c3.737-6.656 10.413-9.206 17.04-6.637 3.13 1.212 5.245 3.547 6.99 6.298 2.111 4.856 1.503 9.423-1.158 13.956-7.418 12.634-16.5 23.697-29.73 30.567-4.134 2.147-8.785 3.396-13.305 4.67a16.174 16.174 0 01-12.81-1.449zM161.964 69.13c-3.437 4.92-6.46 10.201-10.393 14.69-11.779 13.446-26.736 22.316-43.254 28.733a7.787 7.787 0 01-1.334.269c-.246-6.956 4.369-12.849 11.247-13.625 6.114-.691 10.961-3.548 15.315-7.555a62.435 62.435 0 0012.304-15.86c3.387-6.109 9.46-8.606 16.115-6.652z"></path>
  </svg>
))

const StoryCat4 = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="232"
    height="145"
    fill="none"
    viewBox="0 0 232 145">
    <path
      fill="#2A03FC"
      d="M109.187 61.698c-.392-.925-.801-1.844-1.174-2.777a11.059 11.059 0 015.669-14.238 11.059 11.059 0 0114.45 5.105c2.554 5.068 5.007 10.189 7.633 15.22a13.582 13.582 0 011.531 8.4c-.973.246-1.938.524-2.918.734a11.083 11.083 0 00-7.434 5.22 11.083 11.083 0 00-1.026 9.027c1.113 3.764 3.915 5.88 7.3 7.416a37.97 37.97 0 011.731 5.553 10.02 10.02 0 01-1.939 7.509c-1.9 2.673-3.727 5.4-5.701 8.015a2.502 2.502 0 00-.2 3.078c1.494 2.847 2.887 5.748 4.295 8.64a11.043 11.043 0 01.712 8.498 11.043 11.043 0 01-5.549 6.477 11.057 11.057 0 01-14.91-5.034 856.876 856.876 0 01-8.079-16.268 10.691 10.691 0 01.847-11.3 326.776 326.776 0 015.891-8.348 2.066 2.066 0 00.174-2.57c-1.031-1.8-1.95-3.657-2.917-5.49-.435-.974-.827-1.97-1.313-2.919a11.166 11.166 0 01.899-12.458c1.874-2.611 3.749-5.223 5.528-7.9a2.49 2.49 0 00.351-2.019c-1.19-2.57-2.551-5.056-3.851-7.571z"></path>
    <path
      fill="#2A04FC"
      d="M164.176 53.975a10.823 10.823 0 018.215-10.1 10.664 10.664 0 0112.156 5.093c3.406 6.523 6.479 13.22 9.693 19.845a13.223 13.223 0 01-3.06 10.209c-1.7 2.189-3.287 4.477-4.792 6.806a2.63 2.63 0 00-.216 2.158c1.709 3.653 3.56 7.241 5.363 10.85 1.043 4.383-.127 8.177-2.905 11.642-1.613 2.013-2.959 4.238-4.516 6.3a2.437 2.437 0 00-.222 2.932 287.09 287.09 0 014.373 8.806 11.053 11.053 0 01-13.544 15.439 11.054 11.054 0 01-6.327-5.754c-2.57-5.128-5.112-10.27-7.683-15.4a11.374 11.374 0 01.972-12.636c1.811-2.5 3.517-5.081 5.368-7.551a2.44 2.44 0 00.186-2.916c-1.884-3.908-3.653-7.872-5.467-11.813.992-.662 1.982-1.331 2.978-1.989 7.047-4.653 12.699-10.52 15.906-18.466a10.942 10.942 0 00-4.995-13.849 10.938 10.938 0 00-5.176-1.269c-2.107.047-4.202 1.083-6.307 1.663z"></path>
    <path
      fill="#2A03FD"
      d="M31.14 120.277c-1.612 5.537-3.552 10.974-7.244 15.474a37.475 37.475 0 01-7.54 7.289c-4.353 2.972-10.63 1.484-13.925-2.713s-3.35-10.7.508-14.213c5.273-4.8 7.071-10.937 7.577-17.584.268-4.082.182-8.18-.257-12.248-.624-6.37 3.271-11.958 9.325-12.82 6.375-.907 11.77 3.3 12.651 9.855.218 1.621.451 3.239.678 4.859a84.333 84.333 0 01-1.773 22.101z"></path>
    <path
      fill="#2A03FC"
      d="M82.53 102.765c-.41 3.881-.6 7.8-1.269 11.635-1.727 9.878-5.661 18.674-13.435 25.368a11.06 11.06 0 01-14.744-.17 11.063 11.063 0 01-1.48-14.671c.687-.963 1.8-1.633 2.426-2.624 1.45-2.313 2.72-4.74 4.06-7.118.277-.761.623-1.507.823-2.291a44.466 44.466 0 00.968-17.216c-.731-5.222.518-9.425 4.67-12.71a11.156 11.156 0 0117.26 9.315c.209 3.496.477 6.988.72 10.482z"></path>
    <path
      fill="#2CFC03"
      d="M164.18 53.976c2.1-.581 4.2-1.617 6.308-1.66a10.94 10.94 0 0110.171 15.118c-3.2 7.946-8.861 13.812-15.907 18.466-1 .657-1.984 1.327-2.976 1.989-3.749 1.623-7.447 3.388-11.268 4.826a51.266 51.266 0 01-8.892 2.527 70.852 70.852 0 01-8.4.565c-3.384-1.538-6.187-3.654-7.301-7.417a11.069 11.069 0 014.183-12.434 11.08 11.08 0 014.278-1.813c.98-.211 1.946-.488 2.918-.734 7.488-1.625 14.4-4.427 19.925-9.956a28.715 28.715 0 002.544-3.487c1.467-2.001 2.939-3.998 4.417-5.99z"></path>
    <path
      fill="#2DFC03"
      d="M191.54 98.833c-1.8-3.609-3.654-7.2-5.363-10.85a2.63 2.63 0 01.216-2.158c1.5-2.329 3.089-4.617 4.791-6.806a13.22 13.22 0 003.061-10.209 10.633 10.633 0 011.846 2.334c2.314 5.648 6.8 9.09 12.1 11.36a89.037 89.037 0 0013.778 4.536c6.356 1.558 10.327 6.87 9.2 13.073-1.114 6.137-6.833 9.967-13.287 8.775a72.526 72.526 0 01-24.679-9.184c-.53-.32-1.106-.577-1.663-.871z"></path>
    <path
      fill="#04FDFD"
      d="M104.044 16.037a15.913 15.913 0 019.823-14.693 15.908 15.908 0 0121.989 14.701 15.893 15.893 0 01-4.661 11.246 15.903 15.903 0 01-17.335 3.444 15.909 15.909 0 01-9.816-14.698z"></path>
    <path
      fill="#F86EFD"
      d="M16.343 79.549a15.925 15.925 0 11.337-31.85 15.924 15.924 0 0114.605 22.21 15.925 15.925 0 01-14.942 9.64zM81.805 61.727a15.781 15.781 0 01-16 15.872 15.942 15.942 0 01-15.84-16.11 16.16 16.16 0 0115.9-15.94 15.877 15.877 0 0115.94 16.178zM176.653 31.85a15.927 15.927 0 01-14.585-22.212A15.925 15.925 0 01177.005.003c9 .054 15.72 6.986 15.67 16.16a15.751 15.751 0 01-16.022 15.687z"></path>
    <path
      fill="#2DFB05"
      d="M82.527 102.766c-.243-3.494-.51-6.986-.722-10.481a12.212 12.212 0 00-5.482-9.583 30.275 30.275 0 014.824-1.956c7.393-1.635 14.511-3.941 20.359-8.927 1.95-1.662 3.269-4.07 4.844-6.161.976-1.3 1.89-2.638 2.833-3.96 1.3 2.517 2.663 5 3.846 7.57a2.49 2.49 0 01-.351 2.019c-1.78 2.676-3.654 5.289-5.528 7.9a11.175 11.175 0 00-.9 12.459c.486.949.88 1.945 1.315 2.919a67.802 67.802 0 01-25.038 8.201zM64.546 82.968c-4.152 3.285-5.4 7.488-4.67 12.71a44.464 44.464 0 01-.966 17.216c-.2.783-.546 1.529-.823 2.291a77.424 77.424 0 01-21.853 4.945c-1.694.109-3.4.1-5.1.147a84.339 84.339 0 001.773-22.1c7.007-.587 13.918-1.7 20-5.46a25.67 25.67 0 005.712-5.343c1.676-1.966 3.365-3.721 5.927-4.406z"></path>
  </svg>
))

const RattishIndexPage = ({ stories, content }) => {
  return (
    <Layout theme="Rattish">
      {/* Hero */}
      <div className={styles.Hero}>
        <div className={styles.HeroImage}>
          <img
            src={content.frontmatter.headerImage[0].image}
            alt={content.frontmatter.headerImage[0].imageAlt}
          />
        </div>
        <div className={styles.HeroContent}>
          <h1>{content.frontmatter.title}</h1>
          <p>{content.frontmatter.ingress}</p>
        </div>
      </div>

      {/* TeaserTop */}
      <div className={styles.TeaserTop}>
        <div className={styles.TeaserTopInner}>
          <div>
            <Link to={content.frontmatter.sectionTop[0].sectionLink[0].path}>
              <div
                className={
                  content.frontmatter.sectionTop[0].sectionFeaturedImage
                    ? styles.HeadlineImage
                    : ''
                }>
                {content.frontmatter.sectionTop[0].sectionFeaturedImage && (
                  <img
                    src={content.frontmatter.sectionTop[0].sectionFeaturedImage}
                    alt=""
                  />
                )}
                <h3>{content.frontmatter.sectionTop[0].sectionHeader}</h3>
              </div>

              <p>{content.frontmatter.sectionTop[0].sectionBody}</p>
              <span className={styles.link}>
                {content.frontmatter.sectionTop[0].sectionLink[0].label}
              </span>
            </Link>
          </div>
          <div>
            <Link to={content.frontmatter.sectionTop[1].sectionLink[0].path}>
              <h3>{content.frontmatter.sectionTop[1].sectionHeader}</h3>
              <p>{content.frontmatter.sectionTop[1].sectionBody}</p>
              <span className={styles.link}>
                {content.frontmatter.sectionTop[1].sectionLink[0].label}
              </span>
            </Link>
          </div>
        </div>
        <div>
          <div className={styles.BlockPurple}>
            <Link to={content.frontmatter.sectionTop[2].sectionLink[0].path}>
              <div>
                <h3>{content.frontmatter.sectionTop[2].sectionHeader}</h3>
              </div>
              <div>
                <span className={styles.link}>
                  {content.frontmatter.sectionTop[2].sectionLink[0].label}
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.Stories}>
        <div className={styles.StoryBgContainer}>
          <div
            className={styles.StoryBg1}
            style={{ backgroundImage: `url(${bg1})` }}></div>
          <div
            className={styles.StoryBg2}
            style={{ backgroundImage: `url(${bg2})` }}></div>
          <div
            className={styles.StoryBg1Mobile}
            style={{ backgroundImage: `url(${bg1Mobile})` }}></div>
          <div
            className={styles.StoryBg2Mobile}
            style={{ backgroundImage: `url(${bg2Mobile})` }}></div>
        </div>
        <div className={styles.StoryList}>
          {stories.edges.map(({ node }) => (
            <div key={`${node.frontmatter.categories}-${node.frontmatter.uid}`}>
              <Link
                to={`/rattish/${node.frontmatter.categories}/${node.frontmatter.uid}`}>
                <h3>{node.frontmatter.title}</h3>
                <p>{node.frontmatter.description}</p>
                <span className={styles.link}>Läs hela berättelsen</span>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.TeaserStoriesCategory}>
        <BackgroundHand />
        <div>
          <div className={styles.TeaserHeader}>
            <h2>Hitta Rätt-ish</h2>
            <p>Här är områden där du kan läsa mer om dina rättigheter</p>
          </div>
          <div className={`${styles.Teaser} ${styles.Col4}`}>
            <div className={styles.TeaserStoriesBlock}>
              <Link to={content.frontmatter.categoryItems[0].slug}>
                <div className={styles.TeaserStoriesImage}>
                  <StoryCat1 />
                </div>
                <h3>{content.frontmatter.categoryItems[0].name}</h3>
                <p>{content.frontmatter.categoryItems[0].desc}</p>
              </Link>
            </div>

            <div className={styles.TeaserStoriesBlock}>
              <Link to={content.frontmatter.categoryItems[1].slug}>
                <div className={styles.TeaserStoriesImage}>
                  <StoryCat2 />
                </div>
                <h3>{content.frontmatter.categoryItems[1].name}</h3>
                <p>{content.frontmatter.categoryItems[1].desc}</p>
              </Link>
            </div>
            <div className={styles.TeaserStoriesBlock}>
              <Link to={content.frontmatter.categoryItems[2].slug}>
                <div className={styles.TeaserStoriesImage}>
                  <StoryCat3 />
                </div>
                <h3>{content.frontmatter.categoryItems[2].name}</h3>
                <p>{content.frontmatter.categoryItems[2].desc}</p>
              </Link>
            </div>
            <div className={styles.TeaserStoriesBlock}>
              <Link to={content.frontmatter.categoryItems[3].slug}>
                <div className={styles.TeaserStoriesImage}>
                  <StoryCat4 />
                </div>
                <h3>{content.frontmatter.categoryItems[3].name}</h3>
                <p>{content.frontmatter.categoryItems[3].desc}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.Teaser} ${styles.Col3}`}>
        {content.frontmatter.sectionBottom.map((item, index) => (
          <div
            key={`${index}-${item.sectionLink[0].path}`}
            className={styles.TeaserBlock}>
            <Link to={item.sectionLink[0].path}>
              <div className={styles.TeaserImage}>
                <img src={item.sectionFeaturedImage} alt="" />
              </div>
              <div className={styles.TeaserContent}>
                <h3>{item.sectionHeader}</h3>
                <p>{item.sectionBody}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default ({ data }) => {
  const { markdownRemark: post, allMarkdownRemark: edges } = data

  return <RattishIndexPage content={post} stories={edges} />
}
//($cat: String!)
export const RattishIndexQuery = graphql`
  query RattishIndexQuery($filter: [String!]) {
    markdownRemark(frontmatter: { templateKey: { eq: "settings-rattish" } }) {
      frontmatter {
        sectionBottom {
          sectionBody
          sectionFeaturedImage
          sectionHeader
          sectionLink {
            path
          }
        }
        sectionTop {
          sectionBody
          sectionHeader
          sectionFeaturedImage
          sectionLink {
            label
            path
          }
        }
        title
        ingress
        headerImage {
          image
          imageAlt
        }
        categoryItems {
          name
          slug
          desc
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          categories: { in: $filter }
          featuredpost: { eq: true }
          storytype: { eq: "story" }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            categories
            title
            featuredpost
            description
            storytype
            uid
          }
        }
      }
    }
  }
`
